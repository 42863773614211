interface ModalOptions {
    class?: string;
    header?: boolean;
    close?: boolean;
    size: ModalSizes;
    type: ModalTypes;
}

type ModalSizes = 'auto' | 'fluid';
type ModalTypes = 'url' | 'image' | 'inline';

import { URLExtraParams } from '../typings';
import { KEYBOARD } from '../constants';

import appendURLParams from '../helpers/append-url-parameters';
import getURLPart from '../helpers/get-url-part';
import Toast from '../helpers/toast-notification';
import { checkInputFieldEmpty } from '../forms/check-field-empty';
import randomId from '../helpers/random-id';
import Ajax from '../lib/ajax';
import translate from '../helpers/translate';
import appendScripts from '../lib/append-scripts';
import replaceState from '../helpers/replace-state';
import { addScrollbarDifference, removeScrollbarDifference } from '../helpers/scrollbar-difference';
import getCookie from '../helpers/get-cookie';

export default class Modal {
    private staticModal: HTMLDivElement | null = document.querySelector('.modal__overlay--visible');
    private title: string;
    private url: string;
    private id: string;
    private urlExtraParams: URLExtraParams = { partial: 1 };
    private readonly defaultOptions: ModalOptions = {
        close: true,
        header: true,
        size: 'fluid',
        type: 'url',
    };
    private options: ModalOptions = {
        size: 'fluid',
        type: 'url',
    };
    private pagePath: string = window.location.pathname;

    constructor() {
        this.attachStaticEvents();
        this.attachEvents();
    }

    open(): void {
        this.buildOverlay();

        if (this.options.type === 'image') {
            this.openImageModal();
        } else if (this.options.type === 'inline') {
            this.openInlineModal();
        } else {
            this.openModal();
        }
    }

    openImageModal(): void {
        const request = new Ajax();

        request
            .fetchImage(this.url)
            .then((content: string) => {
                this.buildModal(content);
            })
            .catch(() => {
                Toast.show({
                    message: translate(705),
                    type: 'error',
                });

                this.closeModal(this.id);
            });
    }

    openInlineModal(): void {
        const container: HTMLDivElement | null = document.querySelector(this.url);
        const content = container?.innerHTML;

        if (!content) {
            return this.closeModal(this.id);
        }

        this.buildModal(content);
    }

    openModal(): void {
        const request = new Ajax();
        const uri = appendURLParams(this.url, this.urlExtraParams);

        request
            .fetchURLResponse(uri)
            .then((response: Response) => {
                if (response.status === 206) {
                    // Temporary thing to allow rollback of responsive.
                    document.location.href = this.url;
                    return;
                } else if (response.status === 205) {
                    document.location.href = response.headers.get('location') as string;
                    return;
                } else {
                    return response;
                }
            })
            .then((response: Response) => {
                const loadedUrl: URL = response && response.url ? new URL(response.url) : new URL(uri);

                if (loadedUrl.pathname === '/logon.html') {
                    this.options['size'] = 'auto';
                    this.options['header'] = false;
                    this.options['class'] = 'login';
                }

                return response.text();
            })
            .then((content: string) => {
                this.buildModal(content);
            });
    }

    buildOverlay(): void {
        const overlay: HTMLDivElement = document.createElement('div');

        this.id = randomId('modal-');

        overlay.setAttribute('role', 'dialog');
        overlay.setAttribute('aria-label', 'Modal');
        overlay.classList.add('modal__overlay', 'modal__overlay--transparent');
        overlay.setAttribute('id', this.id);

        addScrollbarDifference();

        document.body.appendChild(overlay);
    }

    buildModal(body: string): void {
        const overlay: HTMLDivElement | null = document.querySelector(`.modal__overlay#${this.id}`);
        const modal: HTMLDivElement = document.createElement('div');
        const content: HTMLDivElement = document.createElement('div');
        const size = `modal--${this.options.size}`;

        if (overlay) {
            if (this.options.close) {
                const close: HTMLAnchorElement = document.createElement('a');

                close.href = '#';
                close.setAttribute('aria-label', 'Close');
                close.classList.add('modal__close', 'js--modal__close');

                modal.appendChild(close);
            }

            if (this.options.header) {
                const header: HTMLDivElement = document.createElement('div');
                const h2: HTMLHeadingElement = document.createElement('h2');
                h2.innerText = this.title;

                header.classList.add('modal__hd');
                header.appendChild(h2);

                modal.appendChild(header);
            }

            if (this.options.class) {
                modal.classList.add(`modal--${this.options.class}`);
            }

            modal.classList.add('modal');
            modal.classList.add('modal--visible');
            modal.classList.add(size);
            content.classList.add('modal__cnt');

            modal.appendChild(content);
            overlay.appendChild(modal);

            overlay.classList.remove('modal__overlay--transparent');

            setTimeout(() => {
                window.focus();
                overlay.classList.add('modal__overlay--visible');
            }, 300);

            if (this.options.type === 'image') {
                const image = document.createElement('img');

                image.src = body;
                image.addEventListener('load', (e: Event) => {
                    const height = image.height;
                    const width = image.width;

                    image.height = height;
                    image.width = width;
                    image.alt = this.title;
                });

                content.appendChild(image);

                Analytics.sendEvent({
                    category: 'Modal',
                    action: `Shown - ${this.title}`,
                    label: this.pagePath,
                });
            } else {
                content.insertAdjacentHTML('afterbegin', body);

                const title = modal.querySelector('h2')?.innerText;

                Analytics.sendEvent({
                    category: 'Modal',
                    action: `Shown - ${title}`,
                    label: this.pagePath,
                });
            }

            checkInputFieldEmpty();

            appendScripts(modal);
        }
    }

    closeModal(id?: string): void {
        const modalID: string = id ? `#${id}` : '';
        const element: HTMLDivElement | null = document.querySelector(`.modal__overlay${modalID}`);

        if (element) {
            const closeURL: string | null = element.getAttribute('data-close');

            element.classList.remove('modal__overlay--visible');

            Toast.dismissAll();

            if (closeURL) {
                replaceState(null, '', closeURL);
            } else {
                replaceState(null, '', ' ');
            }

            this.id = '';

            removeScrollbarDifference();

            setTimeout(() => {
                if (element.parentNode) {
                    element.parentNode.removeChild(element);

                    const title = element.querySelector('h2') ? element.querySelector('h2')?.innerText : this.title;

                    Analytics.sendEvent({
                        category: 'Modal',
                        action: `Closed - ${title}`,
                        label: this.pagePath,
                    });
                }
            }, 300);
        }
    }

    // Close modal on ESC
    closeModalOnKeyPress(e: KeyboardEvent): void {
        if (e.key === KEYBOARD.ESC) {
            this.closeModal(this.id);
        }
    }

    // Static modal - add `no-scroll` and get modal ID
    attachStaticEvents(): void {
        if (this.staticModal) {
            addScrollbarDifference();

            this.id = this.staticModal.id;

            const title = this.staticModal.querySelector('h2')?.innerText;

            Analytics.sendEvent({
                category: 'Modal',
                action: `Shown - ${title}`,
                label: this.pagePath,
            });
        }
    }

    setModalParameters(target: HTMLAnchorElement): void {
        const frontendVersion: string | null = getCookie('_fev');
        const url: string | null = target.getAttribute('href');
        const gotoURL: string | null = target.getAttribute('data-to');
        const options: string | null = target.getAttribute('data-modal-options');
        const type: ModalTypes = target.getAttribute('data-modal-type') as ModalTypes;

        if (frontendVersion === '1' && url && type !== 'inline') {
            document.location.href = url;
            return;
        }

        target.blur();

        if (this.id) {
            this.closeModal(this.id);
        }

        this.title = target.title;
        this.urlExtraParams.gotoURL = gotoURL ? gotoURL : getURLPart();

        if (options) {
            const optionsObject = JSON.parse(options);

            this.options = {
                ...this.defaultOptions,
                ...optionsObject,
            };
        } else {
            this.options = { ...this.defaultOptions };
        }

        this.options.type = type ? type : 'url';

        if (url && url !== '#') {
            this.url = url;
            this.open();
        } else {
            Toast.show({ message: translate(700) });
        }
    }

    attachEvents(): void {
        document.addEventListener('click', (e: Event) => {
            const target: EventTarget | null = e.target;

            if (target instanceof HTMLAnchorElement && target.hasAttribute('data-modal')) {
                e.preventDefault();
                this.setModalParameters(target);
            }

            if (target instanceof HTMLAnchorElement && target.matches('.modal .js--modal__close')) {
                e.preventDefault();
                this.closeModal(this.id);
            }

            if (target instanceof HTMLDivElement && target.matches('.modal__overlay.modal__overlay--visible')) {
                e.preventDefault();
                this.closeModal();
            }
        });

        document.addEventListener('keydown', (e: KeyboardEvent) => {
            return this.closeModalOnKeyPress(e);
        });
    }
}
