import { UploadErrors } from './typings';

export const IS_ACTIVE = 'is-active';
export const IS_VISIBLE = 'is-visible';
export const IS_HIDDEN = 'is-hidden';
export const IS_STICKY = 'is-sticky';
export const IS_DISABLED = 'is-disabled';
export const IS_LOADING = 'is-loading';
export const IS_MINIMIZED = 'is-minimized';
export enum CLASS {
    HIDDEN = 'hlp--hidden',
}

// Popout menu related
export const POPOUT_TIMEOUT = 500;
export const DIRECTION_TOP = 'direction-top';
export const DIRECTION_BOTTOM = 'direction-bottom';
export const DIRECTION_RIGHT = 'direction-right';
export const DIRECTION_LEFT = 'direction-left';

// Favorites related
export const FAVORITE_REMOVED = '0';
export const FAVORITE_ADDED = '1';
export const FAVORITE_AND_ALERT_ADDED = '2';

// Profile gallery
export const GALLERY_PICTURE_PREFIX = 'pic';
export const GALLERY_LOADER = 'img-loader--alt';

// Note related
export const NOTE_ADDED = true;

// Mail upload
export const ALLOWED_FILE_TYPES = '.jpg, .jpeg, .gif, .jpe';

// Mail message delete
export const TIMEOUT_MESSAGE_DELETE_UNDO = 5000;

// Forum related
export const FORUM_MESSAGE_LENGTH = 1200;

// Help request related
export const HELP_REQUEST_MESSAGE_LENGTH = 4000;
export const DELETE_ATTACHMENT_CLASS = '.js-attachment__delete';

// WIO Floating CTA delay
export const FLOATING_CTA_DELAY = 5000;

// Profile teaser video
export const MEDIA_PLAYING = 'media-playing';

// Keyboard event codes
export enum KEYBOARD {
    ESC = 'Escape',
    ARROW_LEFT = 'ArrowLeft',
    ARROW_RIGHT = 'ArrowRight',
    BACKSPACE = 'Backspace',
}

// Animation classes
export enum ANIMATION {
    FADE_IN = 'fade-in',
    FADE_OUT = 'fade-out',
    FADE_IN_FAST = 'fade-in--fast',
    FADE_OUT_FAST = 'fade-out--fast',
    SLIDE_OUT = 'slide-out',
    SLIDE_OUT_FAST = 'slide-out--fast',
    CENTER_TO_LEFT = 'center-to-left',
    CENTER_TO_RIGHT = 'center-to-right',
    RIGHT_TO_CENTER = 'right-to-center',
    LEFT_TO_CENTER = 'left-to-center',
}

// Promotion types
export enum PromoType {
    Other = 0,
    Chathost = 1,
    Promotion = 2,
}

export enum FOLDER_TYPE {
    INBOX = 1,
    SENT = 2,
}

// Upload error map
export const UPLOAD_ERROR_MAP: UploadErrors = {
    47: 803, // max size
    48: 801, // unknow error
    49: 801, // no file
    50: 801, // no size
    51: 801, // not uploaded
    67: 800, // not allowed file
    240: 805, // not uploaded
};

// Custom events
export enum EVENT {
    CATEGORY_MENU_VISIBILITY_CHANGE = 'ccmn:category-menu:visibility-change',
    BALANCE_OVERVIEW_REFRESH = 'ccmn:balance-overview:refresh',
    VOTING_SUBMIT = 'ccmn:voting:submit',
    MEDIA_PLAY = 'ccmn:media:play',
    MEDIA_PAUSE = 'ccmn:media:pause',
    MEDIA_CLOSE = 'ccmn:media:close',
}

// Chathost registration related
export enum CHATHOST_REGISTRATION_LABEL {
    STUDIO = 'studio',
    EXISTING_STUDIO = 'existing-studio',
    NEW_ACCOUNT = 'new-account',
}

export enum SELECTOR {
    FIRST_ACCOUNT_SELECTOR = '.js-firstAccID',
}
